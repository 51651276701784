var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { attrs: { title: "Instagram Köprü" } },
        [
          _c("InstagramList", {
            attrs: { list: _vm.list },
            on: { action: _vm.handleActions }
          }),
          _c(
            "div",
            {
              staticClass: "con-pagination-table vs-table--pagination px-0 mt-4"
            },
            [
              _c("vs-pagination", {
                attrs: { total: _vm.getTotalPages },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }