<template>
  <div>
    <vx-card title="Instagram Köprü">
      <InstagramList :list="list" @action="handleActions" />
      <div class="con-pagination-table vs-table--pagination px-0 mt-4">
        <vs-pagination :total="getTotalPages" v-model="page"></vs-pagination>
      </div>
    </vx-card>
  </div>
</template>

<script>
import InstagramList from '@/components/instagram/InstagramList.vue';
import * as _ from 'lodash-es';
import { usePagination, useQueryParams, usePageRefresh } from '@/mixins';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: { InstagramList },
  mixins: [usePagination({ moduleName: 'instagram' }), useQueryParams, usePageRefresh],
  computed: {
    ...mapGetters('instagram', ['list'])
  },
  methods: {
    ...mapActions('instagram', ['getList', 'removeTopic']),
    resetQueryParams() {
      this.$query.page = 1;
      delete this.$query.search;
    },
    setDefaultQueryParams() {
      if (this.$query.hasOwnProperty('search')) return;
      if (!this.$query.hasOwnProperty('page') || !this.$query.hasOwnProperty('perPage')) {
        const queryParams = { ...this.getPaginationParams, ...this.$query };
        this.$router.replace({ query: { ...queryParams } });
      }
    },
    changeRoute(query, { reset, replace }) {
      if (reset) {
        this.resetQueryParams();
      }
      const newPath = { query: { ...this.$query, ...query } };
      const method = replace ? 'replace' : 'push';
      this.$router[method](newPath);
    },

    filterList(type) {
      this.$vs.loading();
      this.getList(type, this.fetchParams).finally(() => {
        this.$vs.loading.close();
      });
    },

    handleActions({ action, payload }) {
      const handleName = 'handle' + _.upperFirst(action);
      this[handleName](action, payload);
    },

    handleAction({ text, successText, action, payload }) {
      if (confirm(text)) {
        this.$vs.loading();
        this[action](payload)
          .then(() => {
            this.$vs.notify({
              color: 'success',
              text: successText
            });
          })
          .finally(() => {
            this.$vs.loading.close();
          });
      }
    },

    handleRemoveTopic(action, { id }) {
      const text = "Başlığı Instagram Köprü'den kaldırmak istediğinize emin misiniz?";
      const successText = 'Başlık Kaldırıldı.';
      this.handleAction({ text, successText, payload: id, action });
    },

    async fetchData() {
      this.setDefaultQueryParams();
      this.setPagination(this.$query);
      await this.filterList(this.$query.tab);
    }
  },
  async created() {
    this.fetchData();
  }
};
</script>
