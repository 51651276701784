var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-table",
        {
          attrs: {
            stripe: "",
            multiple: false,
            sst: true,
            "max-items": 50,
            data: _vm.list,
            noDataText: ""
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return [
                  _c(
                    "tbody",
                    _vm._l(data, function(tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c("vs-td", { attrs: { id: "topic" } }, [
                            _c("div", { staticClass: "flex flex-col " }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "topic__image-wrapper flex-shrink-0 img-loader flex mr-2 relative",
                                  attrs: { id: "img-" + tr.id }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "topic__image  bg-gray-300 rounded overflow-hidden flex items-center justify-center"
                                    },
                                    [
                                      tr.thumbnail
                                        ? _c("img", {
                                            staticClass:
                                              "rounded p-0  object-cover",
                                            attrs: {
                                              src: _vm.getImageUrl(
                                                tr.thumbnail,
                                                "150x0"
                                              )
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "topic__text break-word font-medium text-black overflow-auto max-w-sm min-w-64 my-2"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(tr.topic.topic) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c(
                            "vs-td",
                            {
                              staticClass: "max-w-64 min-w-32 break-word",
                              attrs: { id: "username" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    tr.topic.user && tr.topic.user.username
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _c("vs-td", { attrs: { id: "createDate" } }, [
                            _c("p", { staticClass: "font-medium w-24" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(tr.createdAt, "DD-MM-YYYY")
                                  ) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(tr.createdAt, "HH:mm")
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]),
                          _c("vs-td", { attrs: { id: "updateDate" } }, [
                            _c("p", { staticClass: "font-medium w-24" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(tr.updatedAt, "DD-MM-YYYY")
                                  ) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(tr.updatedAt, "HH:mm")
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]),
                          _c("vs-td", { attrs: { id: "actions" } }, [
                            _c(
                              "div",
                              { staticClass: "flex justify-end" },
                              [
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: "İşlemler" } },
                                  [
                                    _c("vs-button", {
                                      staticClass: "btn btn--action",
                                      attrs: {
                                        color: "secondary",
                                        size: "small",
                                        type: "gradient",
                                        icon: "open_in_browser"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleContentLink(
                                            $event,
                                            tr.topic.id
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "vx-tooltip",
                                  { attrs: { text: "Sil" } },
                                  [
                                    !tr.deleted
                                      ? _c("vs-button", {
                                          staticClass: "btn btn--action",
                                          attrs: {
                                            size: "small",
                                            color: "danger",
                                            icon: "delete",
                                            type: "gradient"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleRemoveTopic(
                                                $event,
                                                tr.topic.id
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { attrs: { "sort-key": "topic" } }, [
                _vm._v("Başlık")
              ]),
              _c("vs-th", { attrs: { "sort-key": "username" } }, [
                _vm._v("Kullanıcı")
              ]),
              false
                ? _c("vs-th", { attrs: { "sort-key": "onedioId" } }, [
                    _vm._v("Onedio ID")
                  ])
                : _vm._e(),
              _c("vs-th", { attrs: { "sort-key": "createDate" } }, [
                _vm._v("Oluşturulma Tarihi")
              ]),
              _c("vs-th", { attrs: { "sort-key": "updateDate" } }, [
                _vm._v("Güncellenme Tarihi")
              ]),
              false
                ? _c("vs-th", { attrs: { "sort-key": "publishDate" } }, [
                    _vm._v("Yayınlanma Tarihi")
                  ])
                : _vm._e(),
              _c("vs-th")
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }