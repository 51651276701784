<template>
  <div>
    <vs-table stripe :multiple="false" v-model="selected" :sst="true" :max-items="50" :data="list" noDataText="">
      <template slot="thead">
        <vs-th sort-key="topic">Başlık</vs-th>
        <vs-th sort-key="username">Kullanıcı</vs-th>
        <vs-th sort-key="onedioId" v-if="false">Onedio ID</vs-th>
        <vs-th sort-key="createDate">Oluşturulma Tarihi</vs-th>
        <vs-th sort-key="updateDate">Güncellenme Tarihi</vs-th>
        <vs-th sort-key="publishDate" v-if="false">Yayınlanma Tarihi</vs-th>
        <vs-th></vs-th>
      </template>
      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td id="topic">
              <div class="flex flex-col ">
                <div class="topic__image-wrapper flex-shrink-0 img-loader flex mr-2 relative" :id="'img-' + tr.id">
                  <div class="topic__image  bg-gray-300 rounded overflow-hidden flex items-center justify-center">
                    <img v-if="tr.thumbnail" :src="getImageUrl(tr.thumbnail, '150x0')" class="rounded p-0  object-cover" />
                  </div>
                </div>
                <div class="topic__text break-word font-medium text-black overflow-auto max-w-sm min-w-64 my-2">
                  {{ tr.topic.topic }}
                </div>
              </div>
            </vs-td>

            <vs-td id="username" class="max-w-64 min-w-32 break-word">
              {{ tr.topic.user && tr.topic.user.username }}
            </vs-td>

            <vs-td id="createDate">
              <p class="font-medium w-24">
                {{ tr.createdAt | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.createdAt | moment('HH:mm') }}
              </p>
            </vs-td>

            <vs-td id="updateDate">
              <p class="font-medium w-24">
                {{ tr.updatedAt | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.updatedAt | moment('HH:mm') }}
              </p>
            </vs-td>

            <vs-td id="actions">
              <div class="flex justify-end">
                <vx-tooltip text="İşlemler">
                  <vs-button
                    @click="handleContentLink($event, tr.topic.id)"
                    color="secondary"
                    size="small"
                    class="btn btn--action"
                    type="gradient"
                    icon="open_in_browser"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="Sil">
                  <vs-button
                    size="small"
                    class="btn btn--action"
                    @click="handleRemoveTopic($event, tr.topic.id)"
                    v-if="!tr.deleted"
                    color="danger"
                    icon="delete"
                    type="gradient"
                  ></vs-button>
                </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { generateImageUrl } from '@/utils/imageHash';
export default {
  props: ['list'],
  data: () => ({
    selected: []
  }),
  methods: {
    handleContentLink($event, id) {
      $event.stopPropagation();
      this.$router.push({ name: 'topic', query: { id } });
    },
    handleRemoveTopic($event, id) {
      $event.stopPropagation();
      const payload = { id };
      this.$emit('action', { action: 'removeTopic', payload });
    },
    getImageUrl(image, size = '300x0') {
      let params = {
        size: size,
        quality: '80',
        imageUrl: image,
        gifToImage: true
      };
      var newUrl = generateImageUrl(params);
      return newUrl;
    }
  }
};
</script>
